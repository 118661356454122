import braintree from 'braintree-web-drop-in';

function loadBraintree(document) {
  const emptyFormError = 'No payment method is available.';
  const errorMessage = document.querySelector('#js-dropin-error-message');
  const submitButton = document.querySelector('#js-dropin-submit-button');
  const braintreeContainer = document.querySelector('#js-dropin-container');

  if (braintreeContainer) {
    const {
      braintreeAmount,
      braintreeUrl,
      braintreeToken,
      braintreeGooglemerchantid,
      braintreeFormtoken,
    } = braintreeContainer.dataset;
    submitButton.setAttribute('disabled', true);
    submitButton.style.display = 'none';

    const setError = (error) => {
      console.error(error);

      // Braintree returns this error if the form is empty
      if (error.message === emptyFormError) {
        return;
      }

      errorMessage.textContent = error.message;
    };

    const clearError = () => {
      errorMessage.textContent = '';
    };

    const config = {
      authorization: braintreeToken,
      selector: '#js-dropin-container',
      applePay: {
        displayName: 'BetterOff',
        paymentRequest: {
          total: {
            label: 'BetterOff',
            type: 'final',
            amount: braintreeAmount,
          },
          currencyCode: 'USD',
          requiredShippingContactFields: [],
          requiredBillingContactFields: [],
          countryCode: 'US',
          supportedNetworks: ['visa', 'masterCard', 'discover', 'amex'],
          merchantCapabilities: [
            'supports3DS',
            'supportsCredit',
            'supportsDebit',
          ],
        },
      },
      googlePay: {
        merchantId: braintreeGooglemerchantid,
        transactionInfo: {
          currencyCode: 'USD',
          totalPriceStatus: 'FINAL',
          totalPrice: braintreeAmount,
        },
      },
      card: {
        cardholderName: {
          required: true,
        },
      },
    };

    braintree.create(config, function (error, dropinInstance) {
      const paymentOptions = dropinInstance.getAvailablePaymentOptions();
      if (paymentOptions.length === 1 && paymentOptions[0] === 'card') {
        submitButton.style.display = 'block';
        submitButton.removeAttribute('disabled');
      }

      if (error) {
        // Handle any errors that might've occurred when CREATING Drop-in
        setError(error);
      }

      async function handlePayment() {
        this && this.setAttribute && this.setAttribute('disabled', true);
        try {
          const { nonce } = await dropinInstance.requestPaymentMethod();

          console.log({ nonce });
          const response = await fetch(braintreeUrl, {
            method: 'POST',
            body: JSON.stringify({ payment_method_nonce: nonce }),
            headers: {
              'X-CSRF-Token': braintreeFormtoken,
              'Content-Type': 'application/json',
            },
            redirect: 'follow',
          });
          if (response.status === 500) {
            Turbo.visit('/500');
          } else if (response.status === 400) {
            window.location.href = window.location.pathname;
            this && this.removeAttribute && this.removeAttribute('disabled');
          } else if (response.status === 200) {
            const { url } = await response.json();
            Turbo.visit(url, { action: 'replace' });
          }
        } catch (error) {
          setError(error);
          this && this.removeAttribute && this.removeAttribute('disabled');
        }
      }

      document
        .querySelector('[data-braintree-id="toggle"]')
        .addEventListener('click', () => {
          submitButton.style.display = 'none';
          clearError();
        });

      dropinInstance.on('paymentOptionSelected', function ({ paymentOption }) {
        if (paymentOption === 'card') {
          submitButton.style.display = 'block';
          submitButton.removeAttribute('disabled');
        } else {
          submitButton.style.display = 'none';
        }
      });

      dropinInstance.on('noPaymentMethodRequestable', function () {
        submitButton.setAttribute('disabled', true);
      });

      submitButton.addEventListener('click', handlePayment);

      dropinInstance.on('paymentMethodRequestable', function (event) {
        if (event.paymentMethodIsSelected && event.type !== 'CreditCard') {
          handlePayment();
        }
      });
    });
  }
}

export default loadBraintree;
