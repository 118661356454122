import Choices from 'choices.js';

const updateRelatedChoice = (
  choice,
  relatedChoice,
  relatedChoiceOptions,
  selected
) => {
  if (selected) {
    const itemsUrl = relatedChoiceOptions.itemsUrl.replace(
      'relatedObject',
      selected
    );
    relatedChoice.setChoices(async () => {
      const request = await fetch(itemsUrl);
      const items = await request.json();
      relatedChoice.clearStore();

      return items.data.map(({ label, value }) => {
        return {
          label,
          value,
          selected: relatedChoiceOptions.selected.includes(value.toString()),
        };
      });
    });
  }
};

const setChoice = (choice, options) => {
  const label = choice.labels[0];
  const { floatingInput, disabled, ...choicesOptions } = options;

  if (floatingInput) {
    choicesOptions.classNames = choicesOptions.classNames || {};
    choicesOptions.classNames.listSingle = `${choicesOptions.classNames.listSingle} choices__list--single input`;
  }

  const newChoice = new Choices(choice, choicesOptions);

  // Move label next to the visible input
  if (floatingInput && label) {
    const visibleInput = newChoice.itemList.element;
    label.remove();

    if (visibleInput.nextSibling) {
      visibleInput.parentNode.insertBefore(label, visibleInput.nextSibling);
    } else {
      visibleInput.parentNode.appendChild(label);
    }
  }

  if (disabled) {
    newChoice.disable();
  }
  return newChoice;
};

const options = (dataset) =>
  (dataset.choices && JSON.parse(dataset.choices)) || {};

const setRelatedObject = (root, relatedObjectId, parentChoice) => {
  const relatedSelector = root.getElementById(relatedObjectId);
  const { dataset: relatedSelectorDataset } = relatedSelector;
  const relatedSelectorOptions = options(relatedSelectorDataset);
  const relatedChoice = setChoice(relatedSelector, relatedSelectorOptions);
  relatedChoice.clearStore();

  updateRelatedChoice(
    parentChoice,
    relatedChoice,
    relatedSelectorOptions,
    parentChoice.passedElement.element.value
  );

  parentChoice.passedElement.element.addEventListener('change', (e) => {
    if (e.detail.value !== null) {
      updateRelatedChoice(
        parentChoice,
        relatedChoice,
        relatedSelectorOptions,
        e.detail.value
      );
    } else {
      relatedChoice.disable();
    }
  });
};

async function loadChoices(root, selector = '.js-choices') {
  const choices = root.querySelectorAll(selector);

  choices.forEach((choice) => {
    const { dataset } = choice;
    const choiceOptions = options(dataset);
    const { relatedObject } = choiceOptions;
    const newChoice = setChoice(choice, choiceOptions);
    if (relatedObject) {
      relatedObject.ids.forEach((relatedObjectId) => {
        setRelatedObject(root, relatedObjectId, newChoice);
      });
    }
  });
}
export default loadChoices;
