function loadScrollToPopup(root) {
  const element = root.querySelector('#js-popup');
  if (element) {
    element.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }
}

export default loadScrollToPopup;
