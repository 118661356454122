// We load JivoChat dynamically as a static <script> doesn't work with Turbo

const chatScript = document.createElement('script');
chatScript.src = '//code.jivosite.com/widget/Qw3DYBPgEy';
chatScript.type = 'text/javascript';

function loadJivoChat() {
  const jivoSiteTagIdentifier = document.querySelector(
    'meta[name="jivo-chat"]'
  );

  if (jivoSiteTagIdentifier) {
    window.jivo_magic_var = undefined;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(
      chatScript.cloneNode(true),
      firstScript
    );
  }
}

export default loadJivoChat;
