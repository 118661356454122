function loadGoToRegistration(root) {
  const inputElements = root.querySelectorAll("[name='registration-option']");
  let url = '';
  if (inputElements.length) {
    const goToRegistration = () => {
      const individualSelected = root.getElementById('input-individual')
        ?.checked;
      const groupSelected = root.getElementById('input-group')?.checked;
      const teamSelected = root.getElementById('input-team')?.checked;

      if (individualSelected) {
        window.location.href = url;
      }
      if (groupSelected) {
        window.location.href = url;
      }
      if (teamSelected) {
        window.location.href = url;
      }
    };
    const nextButton = root.querySelector('#button-next');
    nextButton.addEventListener('click', goToRegistration);

    const enableButton = ({ target }) => {
      document.getElementById('button-next').disabled = false;
      url = target.dataset.url;
    };

    inputElements.forEach((input) => {
      input.addEventListener('change', enableButton);
    });
  }
}

export default loadGoToRegistration;
