import Mailcheck from 'mailcheck';

import addIdempotentListener from './addIdempotentListener';

function fillUpEmailInput(suggestionText) {
  document.getElementById('js-mailcheck').value = suggestionText.full;
  document.getElementById('js-mailcheck_suggestion').innerHTML = '';
}

async function loadMailcheck() {
  const mailcheck = document.getElementById('js-mailcheck');

  if (!mailcheck) {
    return;
  }

  addIdempotentListener(
    mailcheck,
    'blur',
    (event) => {
      Mailcheck.run({
        email: event.target.value,
        suggested(suggestionText) {
          const link = document.createElement('a');
          link.innerHTML = suggestionText.full;
          link.onclick = () => fillUpEmailInput(suggestionText);

          const suggestion = document.getElementById('js-mailcheck_suggestion');
          suggestion.innerHTML = 'Did you mean ';
          suggestion.appendChild(link);
          link.insertAdjacentHTML('afterend', '?');
        },
        empty() {
          document.getElementById('js-mailcheck_suggestion').innerHTML = '';
        },
      });
    },
    'loadMailcheck-blur-mailcheck'
  );
}

export default loadMailcheck;
