import Cookies from 'js-cookie';

import addIdempotentListener from '../utils/addIdempotentListener';

async function setCookieForOrderInviteMessage(root) {
  root.querySelectorAll('.js-set-order-message-cookie').forEach((input) => {
    const key = `order-invite_message-${input.dataset.orderId}`;
    addIdempotentListener(
      input,
      'change',
      (event) => {
        const daysUntilEventStart = parseInt(
          input.dataset.daysUntilEventStart,
          10
        );

        Cookies.set(key, event.target.value, { expires: daysUntilEventStart });
      },
      key
    );
  });
}
export default setCookieForOrderInviteMessage;
