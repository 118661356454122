import VisibilityToggler from './VisibilityToggler';

function hideModals() {
  if (document.body.classList.contains('modal-open')) {
    Array.from(document.getElementsByClassName('modal')).forEach((el) => {
      const toggler = new VisibilityToggler(el);
      toggler.hide();
      el.removeAttribute('arial-modal');
      el.setAttribute('aria-hidden', 'true');
    });
    Array.from(
      document.getElementsByClassName('modal-backdrop')
    ).forEach((el) => el.remove());
    document.body.classList.remove('modal-open');
  }
}

export default hideModals;
