function loadTurnstile() {
  const turnsliteContainer = document.getElementById('js-turnstile-container');
  if (turnsliteContainer) {
    const turnstile = window.turnstile;
    turnstile.execute('#js-turnstile-container', {
      sitekey: turnsliteContainer.dataset.turnstileKey,
      theme: 'light',
      appearance: 'interaction-only',
    });
  }
}

export default loadTurnstile;
