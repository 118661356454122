import { atcb_action, atcb_init } from 'add-to-calendar-button';

import addIdempotentListener from '../utils/addIdempotentListener';

async function loadAddToCalendar(root) {
  const addToCalendarButtons = root.querySelectorAll('.atcb');

  if (addToCalendarButtons.length > 0) {
    atcb_init();
  }

  const buttons = root.querySelectorAll('.calendar');
  buttons.forEach((button) => {
    const config = JSON.parse(button.dataset.calenderConfig);
    addIdempotentListener(button, 'click', (event) => {
      event.preventDefault();
      atcb_action(config, button);
    });
  }, 'loadAddToCalendar-click-calendar');
}
export default loadAddToCalendar;
