import asArray from './asArray';

export type Selector = string | string[];

function queryMultipleSelectors(
  root: Document | HTMLElement,
  selectors: Selector
): NodeListOf<HTMLElement> {
  const orSelector = asArray(selectors).join(', ');

  return root.querySelectorAll(orSelector);
}

export default queryMultipleSelectors;
