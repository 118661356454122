/* eslint no-console:0 */
import 'airbnb-browser-shims';
import '@hotwired/turbo-rails';
import 'bootstrap';
import 'iframe-resizer/js/iframeResizer.contentWindow';

import Rails from '@rails/ujs';
import GLightbox from 'glightbox';

import enableAfterLoad from '../utils/enableAfterLoad';
import hideModals from '../utils/hideModals';
import likesUnlikesLink from '../utils/likesUnlikesLink';
import loadAddToCalendar from '../utils/loadAddToCalendar';
import loadBraintree from '../utils/loadBraintree';
import loadCaptainInputSwitcher from '../utils/loadCaptainInputSwitcher';
import loadChoices from '../utils/loadChoices';
import loadClosePopups from '../utils/loadClosePopups';
import loadFileUpload from '../utils/loadFileUpload';
import loadGoToRegistration from '../utils/loadGoToRegistration';
import loadJivoChat from '../utils/loadJivoChat';
import loadMailcheck from '../utils/loadMailcheck';
import loadNestedForm from '../utils/loadNestedForm';
import loadScrollToPopup from '../utils/loadScrollToPopup';
import loadToggleVisibility from '../utils/loadToggleVisibility';
import loadTurnstile from '../utils/loadTurnstile';
import loadTypeform from '../utils/loadTypeform';
import removeInlineSvgStyle from '../utils/removeInlineSvgStyle';
import setCookieForOrderInviteMessage from '../utils/setCookieForOrderInviteMessage';
import unloadFrameOnModalClose from '../utils/unloadFrameOnModalClose';

Rails.start();
window.Rails = Rails;

const componentRequireContext = require.context('../components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount);

ReactRailsUJS.useContext(componentRequireContext);
loadTypeform();

function resizeIFrame() {
  if ('parentIFrame' in window) {
    // Fix race condition in FireFox with setTimeout
    setTimeout(parentIFrame.size.bind(parentIFrame), 0);
  }
}

function navigateIFrame(url) {
  parentIFrame.sendMessage({ type: 'navigation', data: { url: url } }, '*');
}

// turbo:load is not fired for 400-500 (e.g., 422) responses
// Because of that, we have to hook into both turbo:load and turbo:render
// This will cause our JS to be loaded multiple times in some cases
// Until we have a better solution, remember to make your JS idempotent if it mutates DOM,
// or hook it only to turbo:load if it's global. See:
// https://github.com/hotwired/turbo/issues/520
// https://github.com/hotwired/turbo/pull/425
async function loadJs(root) {
  if ('parentIFrame' in window) {
    parentIFrame.scrollTo(0, 0);
    navigateIFrame(window.location.toString());
    document
      .querySelectorAll('[loading=lazy]')
      .forEach((el) => (el.loading = 'eager'));
    parentIFrame.getPageInfo(({ scrollTop, clientHeight, offsetTop }) => {
      const top = clientHeight / 2 + scrollTop - offsetTop;
      document
        .querySelectorAll('.modal-dialog.-center')
        .forEach((el) => (el.style.top = top + 'px'));
    });
  }
  await Promise.allSettled([
    enableAfterLoad(root),
    likesUnlikesLink(root),
    loadChoices(root),
    loadMailcheck(),
    loadFileUpload(root),
    loadNestedForm(root),
    loadToggleVisibility(root),
    removeInlineSvgStyle(root),
    setCookieForOrderInviteMessage(root),
    loadAddToCalendar(root),
    loadJivoChat(),
    GLightbox(),
    loadCaptainInputSwitcher(root),
    loadGoToRegistration(root),
    loadClosePopups(root),
    loadBraintree(root),
    loadScrollToPopup(root),
  ]);
  resizeIFrame();
  // Sometimes page doesn't fully render on first load, so we need to resize again after a while
  setTimeout(resizeIFrame, 1000);
}

document.addEventListener('turbo:render', () => {
  loadJs(document);
});

document.addEventListener('turbo:load', async () => {
  await loadJs(document);
  unloadFrameOnModalClose();

  if (window.location.hash === '#login') {
    document.getElementById('js-login_modal_button').click();
  }
  window.onloadTurnstileCallback = loadTurnstile;
});

document.addEventListener('turbo:frame-load', ({ target }) => {
  loadJs(target);
});

// Allow breaking out of turbo frame based on server response, until this is solved in Turbo
// Based on: https://github.com/hotwired/turbo/issues/138#issuecomment-922416379
document.addEventListener('turbo:before-fetch-response', (event) => {
  const { url, redirected } = event.detail.fetchResponse.response;
  if (redirected && url.match('turbo_frame=_top')) {
    event.preventDefault();

    const location = new URL(url);
    location.searchParams.delete('turbo_frame');
    Turbo.visit(location.toString());
  }
});

document.addEventListener('turbo:before-cache', () => {
  hideModals();
});
