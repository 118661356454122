import { Root } from './loadToggleVisibility';
import queryMultipleSelectors, { Selector } from './queryMultipleSelectors';
import VisibilityToggler from './VisibilityToggler';

export type VisibilityShowConditionFunction<ConditionOptionType> = (
  el: ConditionOptionType
) => boolean;

class VisibilityUpdater<ConditionOptionType> {
  togglers: VisibilityToggler[] = [];
  showCondition: VisibilityShowConditionFunction<ConditionOptionType>;
  root: Root;
  selector: Selector;

  constructor(
    root: Root,
    selector: string | string[],
    showCondition: VisibilityShowConditionFunction<ConditionOptionType>
  ) {
    this.root = root;
    this.selector = selector;
    this.showCondition = showCondition;
  }

  get elements() {
    return Array.from(queryMultipleSelectors(this.root, this.selector));
  }

  update = async (conditionOption: ConditionOptionType): Promise<void> => {
    this.refreshTogglersList();

    if (this.showCondition(conditionOption)) {
      this.show();
    } else {
      this.hide();
    }
  };

  refreshTogglersList(): void {
    const trackedElements = this.togglers.map((toggler) => toggler.element);
    const newElements = this.elements.filter(
      (el) => !trackedElements.includes(el)
    );
    const newTogglers = newElements.map((el) => new VisibilityToggler(el));
    this.togglers = this.togglers.concat(newTogglers);
  }

  hide(): void {
    this.togglers.forEach((toggler) => toggler.hide());
  }

  show(): void {
    this.togglers.forEach((toggler) => toggler.show());
  }
}

export default VisibilityUpdater;
