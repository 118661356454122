import ensure from './ensure';

class VisibilityToggler {
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;

    if (!this.wasTogglerPreviouslyAttached) {
      this.initializeToggler();
      this.showDisplay = this.showDisplay || this.element.style.display;
    }
  }

  hide() {
    this.element.style.display = 'none';
  }

  show() {
    this.element.style.display = ensure(
      this.showDisplay,
      "element's dataset.showDisplay was removed between toggling"
    );
  }

  get showDisplay() {
    return this.element.dataset['showDisplay'];
  }

  set showDisplay(value) {
    this.element.dataset['showDisplay'] = value;
  }

  initializeToggler() {
    this.element.dataset['toggler'] = 'true';
  }

  get wasTogglerPreviouslyAttached() {
    return this.element.dataset['toggler'] === 'true';
  }
}

export default VisibilityToggler;
