import { createWidget } from '@typeform/embed';

function loadTypeform() {
  const typeformEmbedContainer = document.getElementById('js-typeform_embed');
  if (typeformEmbedContainer) {
    const { typeform } = typeformEmbedContainer.dataset;
    const typeformOptions = JSON.parse(typeform);
    const onQuizSubmit = async () => {
      const response = await fetch(typeformOptions.createPath, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document
            .querySelector("meta[name='csrf-token']")
            ?.getAttribute('content'),
        },
      });
      const { url } = await response.json();
      window.location.href = url;
    };
    createWidget(typeformOptions.formId, {
      container: typeformEmbedContainer,
      onReady: () => {
        console.log('Typeform is ready');
      },
      onSubmit: onQuizSubmit,
      class: 'quizzes__typeform-height',
      hidden: typeformOptions.hidden,
    });
  }
}

export default loadTypeform;
