import addIdempotentListener from '../utils/addIdempotentListener';
import activateFlatpickr from './activateFlatpickr';
import loadChoices from './loadChoices';
import loadToggleVisibility from './loadToggleVisibility';

async function loadNestedForm(root) {
  function handleRemoveButton(removeButton) {
    addIdempotentListener(
      removeButton,
      'click',
      (event) => {
        event.preventDefault();
        const removeElement = () => {
          const target = document.getElementById(removeButton.dataset.target);

          target.getElementsByClassName('js-remove_input')[0].value = true;
          target.style.display = 'none';
        };

        if (removeButton.dataset.confirmation) {
          confirm(removeButton.dataset.confirmation) && removeElement();
        } else {
          removeElement();
        }
      },
      'loadNestedForm-click-handleRemoveButton'
    );
  }

  function handleRemoveNewlyAddedButton(clonedForm) {
    const removeButtons = clonedForm.querySelectorAll(
      '.js-nested_form-remove_button-new'
    );

    removeButtons.forEach((removeButton) =>
      addIdempotentListener(
        removeButton,
        'click',
        (event) => {
          event.preventDefault();
          if (removeButton.dataset.confirmation) {
            confirm(removeButton.dataset.confirmation) && clonedForm.remove();
          } else {
            clonedForm.remove();
          }
        },
        'loadNestedForm-click-handleRemoveNewlyAddedButton'
      )
    );
  }

  function handleAddButton(newForm, addButton) {
    const formParent = newForm.parentElement;
    newForm.remove();
    addIdempotentListener(
      addButton,
      'click',
      (event) => {
        event.preventDefault();
        const addElement = () => {
          const clonedForm = newForm.cloneNode(true);
          handleRemoveNewlyAddedButton(clonedForm);
          if (addButton.dataset.nestedAppendAfter === undefined) {
            formParent.appendChild(clonedForm);
          } else {
            const parent = document.getElementById(
              addButton.dataset.nestedAppendAfter
            );
            parent.appendChild(clonedForm);
          }
          loadChoices(clonedForm, '.js-nested-choices');
          loadToggleVisibility(
            clonedForm,
            'js-toggle_visibility-nested-select'
          );
          activateFlatpickr();
        };
        if (addButton.dataset.confirmation) {
          confirm(addButton.dataset.confirmation) && addElement();
        } else {
          addElement();
        }
      },
      'loadNestedForm-click-handleAddButton'
    );
  }

  root
    .querySelectorAll('.js-nested_form-remove_button')
    .forEach(handleRemoveButton);

  Array.from(root.getElementsByClassName('js-nested_form')).forEach(
    (nestedForm) => {
      const newForm = nestedForm.getElementsByClassName(
        'js-nested_form-new_form'
      )[0];
      const addButton = nestedForm.getElementsByClassName(
        'js-nested_form-add_button'
      )[0];

      if (newForm && addButton) {
        handleAddButton(newForm, addButton);
      }
    }
  );
}

export default loadNestedForm;
