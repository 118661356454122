function likesUnlikesLink(root) {
  const links = root.querySelectorAll('a.js-likes-unlikes');
  links.forEach((element) => {
    element.addEventListener('ajax:beforeSend', () => {
      const currentMethod = element.dataset.method;
      element.classList.add('js-event_like_disabled');
      if (currentMethod === 'post') {
        element.firstElementChild.classList.replace(
          'js-event-like',
          'js-event-unlike'
        );
      } else if (currentMethod === 'delete') {
        element.firstElementChild.classList.replace(
          'js-event-unlike',
          'js-event-like'
        );
      }
    });
    element.addEventListener('ajax:complete', () => {
      const currentMethod = element.dataset.method;
      if (currentMethod === 'post') {
        element.dataset.method = 'delete';
      } else if (currentMethod === 'delete') {
        element.dataset.method = 'post';
      }
      element.classList.remove('js-event_like_disabled');
    });
  });
}

export default likesUnlikesLink;
