/* eslint-disable no-param-reassign */
function loadCaptainInputSwitcher(root) {
  const teamSelect = root.getElementsByClassName('js-join_team_group_select');
  if (teamSelect.length) {
    const captainInputSwitcher = () => {
      const teamSelected = root.getElementsByClassName(
        'js-join_team_group_select'
      )[0];

      const selectedText = teamSelected.querySelector('option:checked').text;
      const { notSelectedTeam } = teamSelected.dataset;
      if (selectedText === notSelectedTeam) {
        Array.from(
          root.getElementsByClassName(
            'js-join_team_registration-alternative_team_input_wrapper'
          )
        ).forEach((el) => {
          el.style.display = 'block';
        });
        Array.from(
          root.getElementsByClassName(
            'js-join_team_registration-alternative_team_input'
          )
        ).forEach((el) => {
          el.disabled = false;
        });
      } else {
        Array.from(
          root.getElementsByClassName(
            'js-join_team_registration-alternative_team_input_wrapper'
          )
        ).forEach((el) => {
          el.style.display = 'none';
        });
        Array.from(
          root.getElementsByClassName(
            'js-join_team_registration-alternative_team_input'
          )
        ).forEach((el) => {
          el.disabled = true;
        });
      }
    };

    captainInputSwitcher();

    root
      .getElementsByClassName('js-join_team_group_select')[0]
      .addEventListener('change', () => {
        captainInputSwitcher();
      });
  }
}

export default loadCaptainInputSwitcher;
