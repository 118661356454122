// If you add to a select (this is Ruby's format)
// ```
// data: {
//   toggle_visibility: [
//     {
//       ['.js-or_selector_1', '.js-or_selector_2'] => {
//         value: 'value_1
//       },
//       '.js-selector_3' => {
//         type: 'individual',
//         checkbox: { hide: '.js-checkbox_selector_1' }
//       },
//       dependencies: {
//         checkbox: ['.js-checkbox_selector_2', '.js-checkbox_selector_3']
//       }
//     },
//     {
//       '.js-selector_4' => [
//         {
//           value: 'value_2'
//         },
//         {
//           checkbox: '.js-checkbox_selector_4'
//         }
//       ]
//     }
//   ]
// }
// ```
// Then:
//   - If you select option with value === 'value_1', then any elements found with selectors `.js-or_selector_1` or `.js-or_selector_2` will be shown. Otherwise, they'll be hidden
//   - If you select option with data-type="individual" and if at least one checkbox found with selector `.js-checkbox_selector_1` is unchecked, then any elements found with selector `.js-selector_3` will be shown. Otherwise, they'll be hidden
//   - If you check or uncheck any checkbox found with with selectors `.js-checkbox_selector_2` or `.js-checkbox_selector_3`, then above mentioned conditions will re-run
//   - if you select option with value === 'value_2' or if at least one checkbox found with selector `.js-checkbox_selector_4` is checked, then any element found with selector `.js-selector_4` will be shown. Otherwise, they'll be hidden

import addIdempotentListener from './addIdempotentListener';
import asArray from './asArray';
import queryMultipleSelectors, { Selector } from './queryMultipleSelectors';
import VisibilityUpdater from './VisibilityUpdater';

export type Root = Document | HTMLElement;

type SimpleCheckboxConfig = Selector;
type ComplexCheckboxConfig = {
  hide: SimpleCheckboxConfig;
};
type CheckboxConfig = SimpleCheckboxConfig | ComplexCheckboxConfig;

type SelectCondition = string | CheckboxConfig;
type SelectConditionsConfig = {
  [dataKey: string]: SelectCondition;
};
type SelectDependenciesConfig = {
  dependencies: {
    checkbox: Selector;
  };
};
type SelectToggleConfig = {
  // `target` is Selector, but TypeScript doesn't allow arrays to be used as keys.
  // Selector can be a string, so this doesn't break TypeScript
  [target: string]: SelectConditionsConfig | SelectConditionsConfig[];
};
type SelectConfig = SelectToggleConfig & Partial<SelectDependenciesConfig>;

function parseConfig<T>(jsonConfig: string | undefined): T[] {
  if (!jsonConfig) {
    return [];
  }

  let targets: T[];
  try {
    targets = JSON.parse(jsonConfig) as T[];
  } catch {
    targets = (jsonConfig as unknown) as T[]; // Config can be a string with a single selector
  }
  targets = asArray(targets);

  return targets;
}

async function setupUpdaters<ConditionOptionType>(
  element: HTMLElement,
  updaters: VisibilityUpdater<ConditionOptionType>[],
  conditionElementSelector: (el: HTMLElement) => ConditionOptionType = (el) =>
    (el as unknown) as ConditionOptionType
) {
  const update = () => {
    const conditionElement = conditionElementSelector(element);
    updaters.forEach((updater) => updater.update(conditionElement));
  };
  const updateName = 'toggleVisibility-change-updater';
  update();
  addIdempotentListener(element, 'change', update, updateName);
}

function checkboxShowCondition(checkboxConfig: CheckboxConfig) {
  return (checkbox: HTMLElement | null) => {
    if (!(checkbox instanceof HTMLInputElement)) {
      return false;
    }
    if (isComplexCheckboxConfig(checkboxConfig)) {
      return !checkbox.checked;
    }
    return checkbox.checked;
  };
}

function checkboxSelector(checkboxConfig: CheckboxConfig) {
  if (isComplexCheckboxConfig(checkboxConfig)) {
    return checkboxConfig.hide;
  }
  return checkboxConfig;
}

function isComplexCheckboxConfig(
  config: CheckboxConfig
): config is ComplexCheckboxConfig {
  return (config as ComplexCheckboxConfig).hide !== undefined;
}

async function toggleVisibilityCheckbox(
  root: Root,
  checkbox: HTMLInputElement
) {
  const targets = parseConfig<CheckboxConfig>(
    checkbox.dataset['toggleVisibility']
  );
  const updaters = targets.map(
    (target) =>
      new VisibilityUpdater(
        root,
        checkboxSelector(target),
        checkboxShowCondition(target)
      )
  );
  setupUpdaters(checkbox, updaters);
}

function isSelectShowConditionFullfiled(
  root: Root,
  selectedOption: HTMLOptionElement | null,
  key: string,
  value: SelectCondition
) {
  if (key === 'checkbox') {
    const checkboxes = Array.from(
      queryMultipleSelectors(root, checkboxSelector(value))
    );
    return checkboxes.some(checkboxShowCondition(value));
  }

  if (!selectedOption) {
    return false;
  }

  const selectedValue =
    key === 'value' ? selectedOption.value : selectedOption.dataset[key];
  return selectedValue === value;
}

function selectShowCondition(
  root: Root,
  orConditions: SelectConditionsConfig | SelectConditionsConfig[]
) {
  return (selectedOption: HTMLOptionElement | null) =>
    asArray(orConditions).some((andConditions) =>
      Object.keys(andConditions).every((key) =>
        asArray(andConditions[key]!).some((andConditionArrayValue) =>
          isSelectShowConditionFullfiled(
            root,
            selectedOption,
            key,
            andConditionArrayValue
          )
        )
      )
    );
}

async function setupDependencies(
  root: Root,
  element: HTMLSelectElement,
  { checkbox: checkboxSelectors }: SelectDependenciesConfig['dependencies']
) {
  if (checkboxSelectors) {
    const checkboxes = queryMultipleSelectors(root, checkboxSelectors);
    Array.from(checkboxes).forEach((checkbox) => {
      const update = () => element.dispatchEvent(new Event('change'));
      const updateName = 'toggleVisibility-change-dependencies';
      addIdempotentListener(checkbox, 'change', update, updateName);
    });
  }
}

async function toggleVisibilitySelect(root: Root, select: HTMLSelectElement) {
  const configs = parseConfig<SelectConfig>(select.dataset['toggleVisibility']);
  const updaters: VisibilityUpdater<HTMLOptionElement | null>[] = [];
  configs.forEach(({ dependencies, ...config }) => {
    Object.entries(config).forEach(([target, conditions]) =>
      updaters.push(
        new VisibilityUpdater<HTMLOptionElement | null>(
          root,
          target as Selector, // target is actually Selector, but TypeScript doesn't allow arrays to be used as keys
          selectShowCondition(root, conditions)
        )
      )
    );
    if (dependencies) {
      setupDependencies(root, select, dependencies);
    }
  });

  setupUpdaters<HTMLOptionElement | null>(select, updaters, (el) =>
    el.querySelector('option:checked')
  );
}

async function loadToggleVisibility(
  root: Root = document,
  selector = 'js-toggle_visibility-select'
) {
  const checkboxes = root.getElementsByClassName(
    'js-toggle_visibility-checkbox'
  );
  Array.from(checkboxes).forEach(
    (el) => el instanceof HTMLInputElement && toggleVisibilityCheckbox(root, el)
  );

  const selects = root.getElementsByClassName(selector);
  Array.from(selects).forEach(
    (el) => el instanceof HTMLSelectElement && toggleVisibilitySelect(root, el)
  );
}

export default loadToggleVisibility;
