function loadClosePopups(root) {
  const closeButtons = root.querySelectorAll('.js-close_popup');
  Array.from(closeButtons).forEach((el) => {
    el.addEventListener('click', ({ currentTarget }) => {
      // eslint-disable-next-line no-param-reassign
      currentTarget.parentElement.style.display = 'none';
    });
  });
}

export default loadClosePopups;
