import flatpickr from 'flatpickr';

import { flatpickrDateConfig, flatpickrDatetimeConfig } from '../constants';

function activateFlatpickr() {
  flatpickr('input[type="datetime-local', flatpickrDatetimeConfig);
  flatpickr('input[type="date', flatpickrDateConfig);
}

export default activateFlatpickr;
