export default async function unloadFrameOnModalClose() {
  document
    .querySelector('#turbo-modal')
    .addEventListener('hidden.bs.modal', ({ target }) => {
      const turboFrame = target.querySelector('turbo-frame');

      if (!turboFrame) return;

      turboFrame.removeAttribute('src');
      turboFrame.innerHTML = '';
    });
}
